import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RemoveBg from "./admin/pages/RemoveBg";
import PrivateRoute from "./core/components/PrivateRoute";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Training = lazy(() =>import("./admin/pages/Training"));
const Bonus = lazy(() =>import("./admin/pages/Bonus"));
const Whitelabel = lazy(() =>import("./admin/pages/Whitelabel"));
const Campaigns = lazy(() =>import("./admin/pages/Campaigns"));
const Team = lazy(() =>import("./admin/pages/Team"));
const Home = lazy(() => import("./admin/pages/Home"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));
const Customize =lazy(()=>import("./admin/pages/Customize"));

const Clipper = lazy(()=>import("./videoClipper/index"));

// Auth
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./auth/pages/ForgotPasswordSubmit")
);

const PasswordSent =lazy(
  () => import("./auth/pages/PasswordSent")
);
const Login = lazy(() => import("./auth/pages/Login"));
const Register = lazy(() => import("./auth/pages/Register"));

// Calendar
const CalendarApp = lazy(() => import("./calendar/pages/CalendarApp"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

// Landing
const Landing = lazy(() => import("./landing/pages/Landing"));
const Pixabay =lazy(()=>import("./admin/pages/Pixabay"));
const Upgrades =lazy(()=>import("./admin/pages/Upgrades"));
const WhitelabelSetting =lazy(()=>import("./admin/pages/WhiteLabelSetting"));


// Users
const UserManagement = lazy(() => import("./users/pages/UserManagement"));
const VideoType = lazy(() => import("./admin/pages/VideoType"));
const Templates  = lazy(() => import("./admin/pages/Templates"));
const Brands = lazy(() => import("./admin/pages/Brands"));
const CreateBrand = lazy(() => import("./admin/pages/CreateBrand"));
const UploadMedia = lazy(() => import("./admin/components/UploadVideo"));
const MediaLibrary = lazy(() => import("./admin/components/MediaLibrary"));
const Media = lazy(() => import("./admin/pages/Media"));
const T17LandscapeEditor = lazy(() => import("./editor/T17Landscape"));
const T17PortraitEditor = lazy(() => import("./editor/T17Portrait"));
const T17SquareEditor = lazy(() => import("./editor/T17Square"));
const T1LandscapeEditor = lazy(() => import("./editor/T1Landscape"));
const T1PortraitEditor = lazy(() => import("./editor/T1Portrait"));
const T1SquareEditor = lazy(() => import("./editor/T1Square"));
const T2LandscapeEditor = lazy(() => import("./editor/T2Landscape"));
const T2SquareEditor = lazy(() => import("./editor/T2Square"));
const T2PortraitEditor= lazy(() => import("./editor/T2Portrait"));
const T3LandscapeEditor = lazy(() => import("./editor/T3Landscape"));
const T3SquareEditor = lazy(() => import("./editor/T3Square"));
const T3PortraitEditor= lazy(() => import("./editor/T3Portrait"));
const T4LandscapeEditor = lazy(() => import("./editor/T4Landscape"));
const T4PortraitEditor = lazy(() => import("./editor/T4Portrait"));
const T4SquareEditor = lazy(() => import("./editor/T4Square"));
const T5LandscapeEditor = lazy(() => import("./editor/T5Landscape"));
const T5SquareEditor = lazy(() => import("./editor/T5Square"));
const T5PortraitEditor= lazy(() => import("./editor/T5Portrait"));
const T6LandscapeEditor = lazy(() => import("./editor/T6Landscape"));
const T6SquareEditor = lazy(() => import("./editor/T6Square"));
const T6PortraitEditor= lazy(() => import("./editor/T6Portrait"));
const T7LandscapeEditor = lazy(() => import("./editor/T7Landscape"));
const T7SquareEditor = lazy(() => import("./editor/T7Square"));
const T7PortraitEditor= lazy(() => import("./editor/T7Portrait"));
const T9LandscapeEditor = lazy(() => import("./editor/T9Landscape"));
const T9SquareEditor = lazy(() => import("./editor/T9Square"));
const T9PortraitEditor= lazy(() => import("./editor/T9Portrait"));
const T10LandscapeEditor = lazy(() => import("./editor/T10Landscape"));
const T10SquareEditor = lazy(() => import("./editor/T10Square"));
const T10PortraitEditor= lazy(() => import("./editor/T10Portrait"));
const T13LandscapeEditor = lazy(() => import("./editor/T13Landscape"));
const T13SquareEditor = lazy(() => import("./editor/T13Square"));
const T13PortraitEditor= lazy(() => import("./editor/T13Portrait"));
const T18LandscapeEditor = lazy(() => import("./editor/T18Landscape"));
const T18SquareEditor = lazy(() => import("./editor/T18Square"));
const T18PortraitEditor= lazy(() => import("./editor/T18Portrait"));
const T22LandscapeEditor = lazy(() => import("./editor/T22Landscape"));
const T22SquareEditor = lazy(() => import("./editor/T22Square"));
const T22PortraitEditor= lazy(() => import("./editor/T22Portrait"));
const T23LandscapeEditor = lazy(() => import("./editor/T23Landscape"));
const T23SquareEditor = lazy(() => import("./editor/T23Square"));
const T23PortraitEditor= lazy(() => import("./editor/T23Portrait"));
const T24LandscapeEditor = lazy(() => import("./editor/T24Landscape"));
const T24SquareEditor = lazy(() => import("./editor/T24Square"));
const T24PortraitEditor= lazy(() => import("./editor/T24Portrait"));
const T8LandscapeEditor = lazy(() => import("./editor/T8Landscape"));
const T8PortraitEditor = lazy(() => import("./editor/T8Portrait"));
const T8SquareEditor = lazy(() => import("./editor/T8Square"));
const T11LandscapeEditor = lazy(() => import("./editor/T11Landscape"));
const T11PortraitEditor = lazy(() => import("./editor/T11Portrait"));
const T11SquareEditor = lazy(() => import("./editor/T11Square"));
const T12LandscapeEditor = lazy(() => import("./editor/T12Landscape"));
const T12PortraitEditor = lazy(() => import("./editor/T12Portrait"));
const T12SquareEditor = lazy(() => import("./editor/T12Square"));
const T14LandscapeEditor = lazy(() => import("./editor/T14Landscape"));
const T14PortraitEditor = lazy(() => import("./editor/T14Portrait"));
const T14SquareEditor = lazy(() => import("./editor/T14Square"));
const T15LandscapeEditor = lazy(() => import("./editor/T15Landscape"));
const T15PortraitEditor = lazy(() => import("./editor/T15Portrait"));
const T15SquareEditor = lazy(() => import("./editor/T15Square"));
const T16LandscapeEditor = lazy(() => import("./editor/T16Landscape"));
const T16PortraitEditor = lazy(() => import("./editor/T16Portrait"));
const T16SquareEditor = lazy(() => import("./editor/T16Square"));
const T19LandscapeEditor = lazy(() => import("./editor/T19Landscape"));
const T19PortraitEditor = lazy(() => import("./editor/T19Portrait"));
const T19SquareEditor = lazy(() => import("./editor/T19Square"));
const T20LandscapeEditor = lazy(() => import("./editor/T20Landscape"));
const T20PortraitEditor = lazy(() => import("./editor/T20Portrait"));
const T20SquareEditor = lazy(() => import("./editor/T20Square"));
const T21LandscapeEditor = lazy(() => import("./editor/T21Landscape"));
const T21PortraitEditor = lazy(() => import("./editor/T21Portrait"));
const T21SquareEditor = lazy(() => import("./editor/T21Square"));
const T25LandscapeEditor = lazy(() => import("./editor/T25Landscape"));
const T25PortraitEditor = lazy(() => import("./editor/T25Portrait"));
const T25SquareEditor = lazy(() => import("./editor/T25Square"));


const Offers =lazy(() => import("./admin/pages/Offers"));
const BgRemove = lazy(() => import("./admin/pages/RemoveBg"));




const AppRoutes = () => {
  return (
    <Routes basename={process.env.PUBLIC_URL}>
      <Route path="/" element={<Login />} /> 
     
      <PrivateRoute path="" element={<Admin />}>
        <PrivateRoute path="/home" element={<Home />} />
        <PrivateRoute path="clipper" element={<Clipper />} />
        <PrivateRoute path="calendar" element={<CalendarApp />} />
        <PrivateRoute path="dashboard" element={<Dashboard />} />
        <PrivateRoute path="customize" element={<Customize />} />
        <PrivateRoute path="offers" element={<Offers />} />
        <PrivateRoute path="whitelabel-setting" element={<WhitelabelSetting />} />
        <PrivateRoute path="T17Landscape" element={<T17LandscapeEditor />} />
        <PrivateRoute path="T17Square" element={<T17SquareEditor />} />
        <PrivateRoute path="T17portrait" element={<T17PortraitEditor />} />
        <PrivateRoute path="T1Landscape" element={<T1LandscapeEditor />} />
        <PrivateRoute path="T1Portrait" element={<T1PortraitEditor />} />
        <PrivateRoute path="T1Square" element={<T1SquareEditor />} />
        <PrivateRoute path="T2Landscape" element={<T2LandscapeEditor />} />
        <PrivateRoute path="T2Square" element={<T2SquareEditor />} />
        <PrivateRoute path="T2Portrait" element={<T2PortraitEditor />} />
        <PrivateRoute path="T3Landscape" element={<T3LandscapeEditor />} />
        <PrivateRoute path="T3Square" element={<T3SquareEditor />} />
        <PrivateRoute path="T3Portrait" element={<T3PortraitEditor />} />
        <PrivateRoute path="T4Landscape" element={<T4LandscapeEditor />} />
        <PrivateRoute path="T4Portrait" element={<T4PortraitEditor />} />
        <PrivateRoute path="T4Square" element={<T4SquareEditor />} />
        <PrivateRoute path="SearchImage" element={<Pixabay />} />
        <PrivateRoute path="Upgrades" element={<Upgrades />} />
        <PrivateRoute path="T5Landscape" element={<T5LandscapeEditor />} />
        <PrivateRoute path="T5Square" element={<T5SquareEditor />} />
        <PrivateRoute path="T5Portrait" element={<T5PortraitEditor />} />
        <PrivateRoute path="T6Landscape" element={<T6LandscapeEditor />} />
        <PrivateRoute path="T6Square" element={<T6SquareEditor />} />
        <PrivateRoute path="T6Portrait" element={<T6PortraitEditor />} />
        <PrivateRoute path="T7Landscape" element={<T7LandscapeEditor />} />
        <PrivateRoute path="T7Square" element={<T7SquareEditor />} />
        <PrivateRoute path="T7Portrait" element={<T7PortraitEditor />} />
        <PrivateRoute path="T9Landscape" element={<T9LandscapeEditor />} />
        <PrivateRoute path="T9Square" element={<T9SquareEditor />} />
        <PrivateRoute path="T9Portrait" element={<T9PortraitEditor />} />
        <PrivateRoute path="T10Landscape" element={<T10LandscapeEditor />} />
        <PrivateRoute path="T10Square" element={<T10SquareEditor />} />
        <PrivateRoute path="T10Portrait" element={<T10PortraitEditor />} />
        <PrivateRoute path="T13Landscape" element={<T13LandscapeEditor />} />
        <PrivateRoute path="T13Square" element={<T13SquareEditor />} />
        <PrivateRoute path="T13Portrait" element={<T13PortraitEditor />} />
        <PrivateRoute path="T18Landscape" element={<T18LandscapeEditor />} />
        <PrivateRoute path="T18Square" element={<T18SquareEditor />} />
        <PrivateRoute path="T18Portrait" element={<T18PortraitEditor />} />
        <PrivateRoute path="T22Landscape" element={<T22LandscapeEditor />} />
        <PrivateRoute path="T22Square" element={<T22SquareEditor />} />
        <PrivateRoute path="T22Portrait" element={<T22PortraitEditor />} />
        <PrivateRoute path="T23Landscape" element={<T23LandscapeEditor />} />
        <PrivateRoute path="T23Square" element={<T23SquareEditor />} />
        <PrivateRoute path="T23Portrait" element={<T23PortraitEditor />} />
        <PrivateRoute path="T24Landscape" element={<T24LandscapeEditor />} />
        <PrivateRoute path="T24Square" element={<T24SquareEditor />} />
        <PrivateRoute path="T24Portrait" element={<T24PortraitEditor />} />
        <PrivateRoute path="T8Landscape" element={<T8LandscapeEditor />} />
        <PrivateRoute path="T8Square" element={<T8SquareEditor />} />
        <PrivateRoute path="T8portrait" element={<T8PortraitEditor />} />
        <PrivateRoute path="T11Landscape" element={<T11LandscapeEditor />} />
        <PrivateRoute path="T11Square" element={<T11SquareEditor />} />
        <PrivateRoute path="T11portrait" element={<T11PortraitEditor />} />
        <PrivateRoute path="T12Landscape" element={<T12LandscapeEditor />} />
        <PrivateRoute path="T12Square" element={<T12SquareEditor />} />
        <PrivateRoute path="T12portrait" element={<T12PortraitEditor />} />
        <PrivateRoute path="T14Landscape" element={<T14LandscapeEditor />} />
        <PrivateRoute path="T14Square" element={<T14SquareEditor />} />
        <PrivateRoute path="T14portrait" element={<T14PortraitEditor />} />
        <PrivateRoute path="T15Landscape" element={<T15LandscapeEditor />} />
        <PrivateRoute path="T15Square" element={<T15SquareEditor />} />
        <PrivateRoute path="T15portrait" element={<T15PortraitEditor />} />
        <PrivateRoute path="T16Landscape" element={<T16LandscapeEditor />} />
        <PrivateRoute path="T16Square" element={<T16SquareEditor />} />
        <PrivateRoute path="T16portrait" element={<T16PortraitEditor />} />
        <PrivateRoute path="T19Landscape" element={<T19LandscapeEditor />} />
        <PrivateRoute path="T19Square" element={<T19SquareEditor />} />
        <PrivateRoute path="T19portrait" element={<T19PortraitEditor />} />
        <PrivateRoute path="T20Landscape" element={<T20LandscapeEditor />} />
        <PrivateRoute path="T20Square" element={<T20SquareEditor />} />
        <PrivateRoute path="T20portrait" element={<T20PortraitEditor />} />
        <PrivateRoute path="T21Landscape" element={<T21LandscapeEditor />} />
        <PrivateRoute path="T21Square" element={<T21SquareEditor />} />
        <PrivateRoute path="T21portrait" element={<T21PortraitEditor />} />
        <PrivateRoute path="T25Landscape" element={<T25LandscapeEditor />} />
        <PrivateRoute path="T25Square" element={<T25SquareEditor />} />
        <PrivateRoute path="T25portrait" element={<T25PortraitEditor />} />


        
        <PrivateRoute path="faq" element={<Faq />} />
        <PrivateRoute path="help" element={<HelpCenter />} />
        <PrivateRoute path="training" element={<Training />} />
        <PrivateRoute path="whitelabel" element={<Whitelabel />} />
        <PrivateRoute path="bonus" element={<Bonus />} />
        <PrivateRoute path="team" element={<Team />} />
        <PrivateRoute path="video-type" element={<VideoType />}/>
        <PrivateRoute path="remove-background" element={<RemoveBg />}/>
        <PrivateRoute path="templates" element={<Templates />}/>
        <PrivateRoute path="campaigns" element={<Campaigns />}/>
        <PrivateRoute path="media" element={<Media />}/>
        <PrivateRoute path="brands" element={<Brands />}/>
        <PrivateRoute path="create-brand" element={<CreateBrand />}/>
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
        </PrivateRoute>
        <PrivateRoute
          path="projects"
          element={
            <Navigate
              to={`/${process.env.PUBLIC_URL}/under-construction`}
              replace
            />
          }
        />
        <PrivateRoute path="user-management" element={<UserManagement />} />
      </PrivateRoute>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ForgotPasswordSubmit />} />
      <Route path="email-sent" element={<PasswordSent />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="under-construction" element={<UnderConstructions />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route
        path="*"
        element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
