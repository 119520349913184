import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./auth/contexts/AuthProvider";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import usePageTracking from "./core/hooks/usePageTracking";
import { useProfileInfo } from "./admin/hooks/useProfileInfo";
import { fetchProfileInfo } from "./admin/hooks/useProfileInfo";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}
const plan = ["5", "21", "23"];
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true,
    },
  },
});

const checkIfWhitelableVisible = (userPlan: any) => {

  //@ts-ignore
  if (userPlan.some(value => plan.includes(value)))
    return true;
}


function App() {
  const [whitelabelTitle, setWhitelabelTitle] = useState("");
  const [isWhiteLableUser, setWhiteLabelUser] = useState(false);
  useEffect(() => {
    fetchProfileInfo().then((resp) => {

      //@ts-ignore
      if (resp?.data?.created_by != undefined) {
         //@ts-ignore
         console.log(resp?.data?.created_by);
        //@ts-ignore
        setWhitelabelTitle(resp?.data?.created_by?.brand_name);
        //@ts-ignore
        document.title = resp?.data?.created_by?.brand_name;
        
      }
      else{
        //@ts-ignore
          document.title = process.env.REACT_APP_NAME
        
      }

    }

    )

    document.title = whitelabelTitle;
  }, [])
  usePageTracking();

  return (
    <React.Suspense fallback={<Loader />}>
      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <QueryWrapper>
              <SnackbarProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </SnackbarProvider>
            </QueryWrapper>
          </SettingsProvider>
          <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.Suspense>
  );
}

export default App;
