import Box, { BoxProps } from "@material-ui/core/Box";
import { ReactComponent as IconSvg } from "../assets/icon.svg";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = true, size = 40, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <IconSvg height={size} width={size} />
    </Box>
  );
};

export default Logo;
