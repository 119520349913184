import axios from "../../api/axios";
import { useMutation } from "react-query";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<string> => {
 
  const {data } = await axios.post("/login", JSON.stringify({ email, password }),
  {
      headers: {
          'Content-Type': 'application/json',
          
      }

  });
  return data?.data?.access_token;
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return { isLoggingIn: isLoading, login: mutateAsync };
}
