import axios from "../../api/axios";
import { useQuery } from "react-query";
import { ProfileInfo } from "../types/profileInfo";


//@ts-ignore
const key =JSON.parse(window.localStorage?.getItem("authkey"))
export const fetchProfileInfo = async (): Promise<ProfileInfo> => {

  const { data } = await axios.get("/profile",
    {
      headers: {
        'Authorization': 'Bearer ' + key,

      }
    });
  return data;
};

export function useProfileInfo() {
  return useQuery("profile-info", () => fetchProfileInfo());
}
