import React, { useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { LoadingButton } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { ChangeEvent } from "react";
import { Button } from "@material-ui/core";

const RemoveBg = () => {
    const [image, setImage] = useState();
    const [isImageVisible, setImageVisible] = useState(false);
    const[isLoading,setIsLoading] =useState(false);

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const formData = new FormData();
        //@ts-ignore
        formData.append("image_file", event.target.files[0]);
        const response = await fetch("https://sdk.photoroom.com/v1/segment", {
            method: "POST",
            headers: {
                "x-api-key": "68e8a4d4d7d312e21c83eb669b2a5bfb8aaad442"
            },
            body: formData
        })
        const outputBlob = await response.blob();
        //@ts-ignore
        setImage(URL.createObjectURL(outputBlob));
        setImageVisible(true);

    }

    const habdleImageVisiblity = () => {
        setImageVisible(false);
        setIsLoading(false);
        
    }
    return (<React.Fragment>

        <Card sx={{ mt: 2 }}>
            <CardContent>
                {!isImageVisible ?
                    <Grid container sx={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Grid xs={12}>
                            <LoadingButton
                                loading={isLoading}
                                component="label"

                                sx={{
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <Box ><img
                                    src="https://revideo-pro.s3.amazonaws.com/templates/upload.png"
                                    style={{ width: "100%" }}
                                ></img>
                                </Box>
                                <input type="file" accept="image/png, image/jpeg " onChange={handleChange} hidden />
                            </LoadingButton>



                        </Grid>
                    </Grid> :

                    <Grid container columns={12} spacing={2}  >

                        <Grid item xs={12} sm={12} md={12}>
                            <Card  >
                                <CardContent sx={{ p: '5%', borderRadius: "12px" }} >



                                    <img src={image} width="100%"></img>
                                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                        <Button
                                            component="a"
                                            href={image}
                                            target="_blank"

                                            variant="contained"
                                        >
                                            Download
                                        </Button> &nbsp;&nbsp;&nbsp;

                                        <Button
                                            component="a"
                                            onClick={habdleImageVisiblity}
                                            variant="outlined"
                                        >
                                            Back
                                        </Button>
                                    </Grid>


                                </CardContent>

                            </Card>
                        </Grid>
                        

                    </Grid>





                }
            </CardContent>
        </Card>
    </React.Fragment>)
}

export default RemoveBg;