import axios from "../../api/axios";
import { useMutation } from "react-query";

const logout = async (): Promise<string> => {

  //@ts-ignore
  var key = JSON.parse(localStorage.getItem("authkey"));
  const { data } = await axios.get("/logout",
  {
    headers: {
      'Authorization': 'Bearer '+ key ,
      
  }
  }
  );
  return data;
};

export function useLogout() {
  const { isLoading, mutateAsync } = useMutation(logout);

  return { isLoggingOut: isLoading, logout: mutateAsync };
}
