import axios from "../../api/axios";
import { useQuery } from "react-query";
import { UserInfo } from "../types/userInfo";

const fetchUserInfo = async (key?: string): Promise<UserInfo> => {
  const { data } = await axios.get("/profile", 
  {
    headers: {
      'Authorization': 'Bearer '+ key ,
      
  }
  });
  return data.data;
};

export function useUserInfo(key?: string) {
  return useQuery(["user-info", key], () => fetchUserInfo(key), {
    enabled: !!key,
  });
}
